import React, { useRef, useState } from "react";

const VideoEdicion= ({ videoSrc }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleLoadedData = () => {
    setIsLoaded(true);
  };

  return (
    <div className="flex flex-col w-full max-w-3xl mx-auto px-4">
      <h1>AUTOEDITA TUS STORY PARA DIFUNDIR EN REDES</h1>
      <div className="mb-4 text-lg font-semibold">
        {isLoaded
          ? "Video cargado. ¡Disfruta!"
          : "Cargando video... Por favor espere."}
      </div>
      <div className="relative aspect-video bg-gray-200 rounded-lg overflow-hidden">
        <video
          ref={videoRef}
          className="w-full h-full"
          src={videoSrc}
          controls
          preload="auto"
          onLoadedData={handleLoadedData}
        />
      </div>
      {isLoaded && (
        <a
          href={videoSrc}
          className="mt-4 w-full py-3 bg-blue-100 text-blue-800 rounded-lg text-center text-lg font-semibold hover:bg-blue-200"
        >
          Click aquí para descargar
        </a>
      )}
    </div>
  );
};

export default VideoEdicion;
