import React, { useState } from "react";

const WarningPopup = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-red-600 text-white p-4 flex justify-between items-center">
      <div className="flex items-center">
        <span className="text-2xl mr-2">⚠️</span>
        <div>
          <h2 className="font-bold">RESPONSABILIDADES DE LISUAL</h2>
          <p className="text-sm">
            En Lisual no nos responsabilizamos si la cámara deja de funcionar,
            el problema es de energía o internet en tu cámara, una vez que estés
            seguro de que este problema está resuelto comunícate con nosotros.
          </p>
        </div>
      </div>
      <button
        onClick={() => setIsVisible(false)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Ok, comprendo ✅
      </button>
    </div>
  );
};

export default WarningPopup;
