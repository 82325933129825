// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LandingPage from "./LandingPage";
import NotificationPage from "./NotificationPage";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "dashboard/:identifier",
    element: <App />,
  },
  {
    path: "notifications/:userid",
    element: <NotificationPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
