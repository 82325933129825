import React, { useState, useEffect, useCallback } from "react";
import {
  Bell,
  Menu,
  Camera,
  Clock,
  Archive,
  Home,
  Users,
  Settings,
  CreditCard,
  HelpCircle,
  BookUser,
  Book,
  PresentationIcon,
  DoorOpen,
  CloudSunIcon,
  Video,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import Calendar from "./Calendar.js";
import "./App.css";
import logo from "./media/lisualpro.png";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase-config";
import ImageGallery from "./ImageGallery.js";
import UserProfileCard from "./Profile.js";
import NotificationForm from "./NotificationForm.js";
import Instagram from "./Instagram.js";
import Timelapse from "./Timelapse.js";
import FAQTab from "./faq.js";
import WeatherComponent from "./weather.js";
import WhatsAppChatButton from "./whatsapp.js";
import ideapng from "./media/idea.png";
import VideoFull from "./VideoFullScreen.js";
import {useLocation,useParams, useNavigate } from "react-router-dom";

const ConstructionDashboard = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("Inicio");
  const [objects, setObjects] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userdata, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [dayTime, setDayTime] = useState("");

  const livecam = `//www.teleport.io/api/v2/player?feedid=${userdata.feedID}&playmode=liveimageloop&imageplayspeed=1fps&playframeskipinterval=day&playframecount=600`;
  const timelapse = `https://www.teleport.io/api/v2/video-get?feedid=${userdata.feedID}&videoquery=all-time&videotype=live`;
  const location = useLocation();
  const word = location.state?.keyword || "No word provided";

  const fetchPost = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "usuarios"));
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setObjects(newData);

      const userData = newData.find(
        (element) => element.identifier === identifier
      );
      if (userData) {
        setUserData(userData);
      } else {
        // If no user is found, redirect to landing page
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    fetchPost();
    const currentTime = new Date();
    if (currentTime.getHours() < 12) {
      setDayTime("buen día  👋🏼");
    } else if (currentTime.getHours() >= 12 && currentTime.getHours() < 18) {
      setDayTime("buenas tardes  👋🏼");
    } else {
      setDayTime("buenas noches  👋🏼");
    }
  }, [identifier]); // Add identifier to dependency array

  const handleIframeLoad = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Archivo Fotografico":
        return (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Archivo Fotografico</h2>
            <Calendar
              mode="single"
              selected={selectedDate}
              onDateSelect={setSelectedDate}
              className="w-full max-w-sm mx-auto rounded-md border"
            />
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-2">
                Fotos del dia {selectedDate.toDateString()}
              </h3>
              <ImageGallery selectedDate={selectedDate} user={userdata} />
            </div>
          </div>
        );
      case "Inicio":
        return (
          <div className="h-full flex flex-col sm:px-40">
            <h2 className="text-xl font-semibold mb-4 mx-auto">
              Hola, {dayTime}!
            </h2>
            <h2 className="text-xl font-semibold mb-4">Inicio</h2>
            <div className="flex-grow relative w-screen overflow-hidden iframe-container">
              <iframe
                src={livecam}
                title="Live Image"
                className="absolute items-center justify-center h-full rounded-lg iframe-content"
                onLoad={handleIframeLoad}
                allowFullScreen
              />
            </div>
          </div>
        );
      case "Timelapse":
        return (
          <div className=" flex items-center justify-center">
            <div className="w-full md:w-1/2 mx-4">
              <Timelapse videoSrc={timelapse} />
            </div>
            <div className="hidden md:block flex-shrink-0 w-1/4">
              <img
                src={ideapng}
                className="w-full h-auto"
                alt="Idea lightbulb"
              />
            </div>
          </div>
        );
      case "Whatsapp + Correo":
        return (
          <div className="p-4">
            <NotificationForm id={userdata}></NotificationForm>
          </div>
        );
      case "Redes Sociales":
        return (
          <div className="p-4">
            <Instagram videoSrc={userdata.videoEdit}></Instagram>
          </div>
        );
      case "Ayuda":
        return (
          <div className="p-4">
            <FAQTab></FAQTab>
          </div>
        );
      case "Clima":
        return (
          <div className="flex flex-col items-center min-h-screen">
            <h2 className="text-center text-3xl font-semibold mb-4">
              Clima actual
            </h2>
            <WeatherComponent></WeatherComponent>
          </div>
        );
      case "Referidos":
        return (
          <div className="p-4">
            <VideoFull
              videoSrc="https://lisual.com/wp-content/uploads/2024/09/EDITADO-REFERIDO.mp4"
              texto="Sistema de referidos"
              linkWPP="https://wa.link/ffiqjj"
            ></VideoFull>
          </div>
        );
      case "Quiero una mentoria":
        return (
          <div className="p-4">
            <VideoFull
              videoSrc="https://lisual.com/wp-content/uploads/2024/09/Techina-VSL-We-Work-Pantalla_FHD_DOWNLOAD.mp4"
              texto="CONSEGUI MAS VENTAS PARA TU EMPRESA DE FORMA GARANTIZADA"
              linkWPP="https://techinalaunch.com/agendar"
            ></VideoFull>
          </div>
        );
      default:
        return (
          <div>
            <h2 className="text-xl font-semibold mb-4">{activeTab}</h2>
            <div className="bg-white rounded-lg shadow p-4">
              <img
                src="/api/placeholder/800/400"
                alt="Seccion en progreso!"
                className="w-full h-48 object-cover rounded"
              />
            </div>
          </div>
        );
    }
  };

  const menuItems = [
    { name: "Inicio", icon: <Home size={24} /> },
    { name: "Timelapse", icon: <Archive size={24} /> },
    { name: "Archivo Fotografico", icon: <Camera size={24} /> },
    { name: "Whatsapp + Correo", icon: <BookUser size={24} /> },
    { name: "Redes Sociales", icon: <PresentationIcon size={24} /> },
    { name: "Clima", icon: <CloudSunIcon size={24} /> },
    { name: "Ayuda", icon: <HelpCircle size={24} /> },
    { name: "Referidos", icon: <HelpCircle size={24} /> },
    { name: "Quiero una mentoria", icon: <HelpCircle size={24} /> },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`
          fixed inset-y-0 left-0 z-30 bg-sky-100 text-black transition-all duration-300 ease-in-out overflow-auto
          ${isSidebarOpen ? "w-64" : "w-0"}
        `}
      >
        <div className="p-4">
          <img src={logo} alt="Logo" className="h-25 w-full object-contain" />
        </div>
        <nav className="mt-2">
          <ul className="space-y-2 px-2 mb-4">
            {menuItems.map((item) => (
              <li
                key={item.name}
                className={`border-blue-600 bg-white border-2 p-2 rounded-3xl cursor-pointer ${
                  activeTab === item.name
                    ? "bg-white border-4 border-blue-600"
                    : "hover:border-4"
                } `}
                onClick={() => {
                  setActiveTab(item.name);
                  if (window.innerWidth < 768) {
                    setIsSidebarOpen(false);
                  }
                }}
              >
                <div className="flex items-center">
                  {item.icon}
                  <span className="ml-3 text-lg">{item.name}</span>
                </div>
              </li>
            ))}
            <li
              className={`border-blue-600 bg-white border-2 p-4 rounded-3xl cursor-pointer hover:border-4`}
              onClick={() => {
                navigate("/");
              }}
            >
              <div className="flex items-center">
                <DoorOpen />
                <span className="ml-3 text-lg">Salir</span>
              </div>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Sidebar toggle button */}
      <button
        onClick={toggleSidebar}
        className={`
          fixed top-1/2 transform -translate-y-1/2 z-40
          bg-sky-100 text-black p-2 rounded-r-md
          transition-all duration-300 ease-in-out
          ${isSidebarOpen ? "left-64" : "left-0"}
        `}
      >
        {isSidebarOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
      </button>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden ml-0 transition-all duration-300 ease-in-out">
        <WhatsAppChatButton
          phoneNumber="5491169572365"
          message={"Tengo una consulta."}
        ></WhatsAppChatButton>
        {/* Dashboard content */}
        <main
          className={`flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6 transition-all duration-300 ease-in-out ${
            isSidebarOpen ? "ml-64" : "ml-0"
          }`}
        >
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

export default ConstructionDashboard;
