import React from 'react';
import { MessageCircle } from 'lucide-react';

const WhatsAppChatButton = ({ phoneNumber, message }) => {
  const openWhatsAppChat = () => {
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <button
      onClick={openWhatsAppChat}
      className="fixed bottom-8 right-5 bg-green-500 text-white p-6 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300 z-50"
      aria-label="Open WhatsApp Chat"
    >
      <MessageCircle size={24} />
    </button>
  );
};

export default WhatsAppChatButton;